import Experience from "../../models/Experience";

const workData: Experience[] = [
  {
    role: "Senior Software Engineer",
    date: "Sep. 2024 - Present",
    company: "Disney Streaming",
    city: "Santa Monica, CA",
    info: "Full Time",
    keywords: ["TypeScript", "JavaScript", "React", "HTML / CSS"],
    link: "https://disney.com/",
    content: [],
  },
  {
    role: "Chief Product Officer",
    date: "Mar. 2019 - Aug. 2024",
    company: "NotMe Solutions Inc.",
    city: "Los Angeles, CA",
    info: "Full Time",
    keywords: [
      "React Native",
      "React",
      "TypeScript",
      "JavaScript",
      "HTML / CSS",
      "Swift",
    ],
    link: "https://not-me.com/",
    content: [
      {
        children: (
          <>
            <p>
              NotMe Solutions is a tech startup specializing in workplace
              solutions, offering products like a misconduct reporting app and
              an analytics dashboard. As a founding member, I played a crucial
              role, leading project management and making key technology
              decisions.
            </p>
            <p>
              I designed and developed the product architecture, building the
              first iOS app in Swift. Additionally, I led the development of a
              cross-platform React Native version of the app. Furthermore, I
              spearheaded the development of the admin web dashboard and
              analytics using Vue. I also created a PWA version of the mobile
              app in React. Implementing notifications, analytics, and telemetry
              using Firebase and Sentry, I ensured seamless functionality.
              Moreover, I designed the entire UI/UX of the projects using Sketch
              and Figma.
            </p>
            <p>
              Leading a team of 5, I oversaw project development, ensured team
              coordination, and acted as the primary communicator with other
              teams for collaboration. I established the Scrum and sprint
              processes as the team scaled up. Additionally, I implemented a
              comprehensive testing process using Jest. While supervising
              testing and validation procedures, I maintained code quality,
              conducted code analysis, performed UI/UX testing, and conducted
              process reviews.
            </p>
          </>
        ),
      },
    ],
  },
  {
    role: "Intern iOS / tvOS Developer",
    date: "Aug. 2017 - Dec. 2017",
    company: "Kanopy",
    city: "San Francisco, CA",
    info: "Full Time",
    keywords: ["Swift"],
    link: "https://www.kanopy.com/",
    content: [
      {
        // date: "Aug. 2017 - Sep. 2017",
        children: (
          <>
            <p>
              Worked to adapt the existing company iOS app to tvOS as an
              extension. Collaborated with a code-sharing mechanism to
              streamline development and maintain consistency across both iOS
              and tvOS.
            </p>
            <p>
              Collaboration was integral, and I closely collaborated with a
              designer to ensure the app adhered to Apple's Human Interface
              Guidelines, guaranteeing a seamless and user-friendly experience
              for our audience. This endeavor marked my first time developing
              for the Apple TV platform and provided a tremendous learning
              opportunity. I acquired valuable expertise in working with
              AVPlayer and handling the m3u8 format, enriching my understanding
              of media playback and content delivery on this platform.
            </p>
            <p>
              After weeks of testing conducted by the entire team, the
              application was successfully launched on the App Store, marking a
              significant milestone for our team. By the end of my internship,
              the app had been used by over 2,700 unique users.
            </p>
          </>
        ),
      },
    ],
  },
  {
    role: "iOS Developer",
    date: "Feb. 2017 - Jul 2017",
    date_second: "Jan. 2018 - Feb. 2019",
    company: "Metalab (ex: 3IE) - EPITA Innovation Lab",
    city: "Paris, FR",
    info: "Full Time",
    keywords: ["Swift", "Angular"],
    content: [
      {
        children: (
          <p>
            I joined the Innovation Institute of EPITA after a rigorous
            recruitment process, competing with hundreds of students from the
            school. During this time, I worked on a Snapchat-like project named
            Windchat. The goal of the project was to develop a Snapchat-like app
            from scratch on iOS using Swift. This project aimed to test our
            ability to create a complete application with an authentication
            layer and all the basic features of Snapchat, including taking
            pictures using AVCaptureSession, adding text to images, managing
            friends, and sending and receiving images.
          </p>
        ),
      },
      {
        children: (
          <p>
            In the laboratory, my role was to work as an iOS/Swift developer,
            learning all the business processes involved in application
            development. The first project we undertook was the complete design
            and implementation of <b>Epilife</b>, the scheduling application
            used by students of the IONIS group to view their courses.
          </p>
        ),
      },
      {
        children: (
          <p>
            For two years, I provided iOS courses to third-year students at
            EPITA. During the first year, I worked as an assistant, and in the
            second year, I took on the role of instructor. My responsibilities
            included conducting practical sessions, facilitating the learning
            process, creating exercises, and assisting students.
          </p>
        ),
      },
      {
        children: (
          <p>
            I worked on an iOS application to control HUE lights using ARKit.
            Additionally, I developed an iOS application to interact with a
            poster using ARKit, along with a web platform to control all data
            displayed on top of the poster. This project was showcased during
            the school's open day to demonstrate current and previous projects
            of the laboratory.
          </p>
        ),
      },
      {
        children: (
          <p>
            During this time, I learned how to interact with a team and
            collaborate with multiple departments in a company while still being
            in school. Additionally, I acquired skills in project architecture
            and gained a better understanding of working on proof of concepts
            and technology exploration. In my final months in the laboratory, I
            also worked as an assistant to onboard new students through the
            laboratory processes.
          </p>
        ),
      },
    ],
  },
  {
    role: "Intern iOS Developer",
    date: "Jun. 2016 - Aug 2016",
    company: "EasyMovie",
    city: "Paris, FR",
    info: "Full Time",
    keywords: ["Swift"],
    link: "https://www.easy.movie/",
    content: [
      {
        children: (
          <p>
            I worked with a small team on a brand new project at EasyMovie, a
            startup focused on simplifying the video creation process for
            businesses and individuals. Their platform offers user-friendly
            tools and features, enabling users to easily create, edit, and
            produce professional-quality videos without the need for extensive
            technical expertise.
          </p>
        ),
      },
      {
        children: (
          <p>
            During the internship, the focus was on understanding the project's
            challenges and requirements, as well as learning Swift 2 for iOS
            development. The main task involved ensuring the smooth
            functionality of the existing iOS application with an API. This was
            achieved by utilizing the Alamofire library, which facilitated
            effective communication between the project and the API.
          </p>
        ),
      },
      {
        children: (
          <p>
            I worked on implementing drag and drop functionality in the
            application to enhance the overall user experience. Throughout the
            internship, I not only deepened my technical expertise but also made
            significant contributions to improving the project's usability and
            overall user experience.
          </p>
        ),
      },
    ],
  },
];

export default workData;
