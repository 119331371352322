import React from "react";
import "./styles.scss";
import memojiThumbsUp from "../../../assets/memoji-thumbs-up.png";
import Tag from "../../../components/Tag";
import SquareIcon from "../../../components/SquareIcon";
import activityData from "../../../assets/data/activity";

const ActivitySection: React.FC = () => {
  return (
    <section className="activity-section">
      <div className="activity-section-container">
        <div className="activity-section-title-container">
          <img
            height={100}
            width={100}
            alt="memojiThumbsUp"
            src={memojiThumbsUp}
          />
          <h4 className="activity-section-title">
            I am a{" "}
            <span className="activity-section-green">software engineer</span>{" "}
            with a strong focus on{" "}
            <span className="activity-section-green">
              web and mobile development
            </span>
            , and I am particularly passionate about{" "}
            <span className="activity-section-yellow">UI design</span>.
            {/* With my
            extensive experience in{" "}
            <span className="activity-section-blue">product management</span>, I
            am well-equipped to oversee projects from the initial concept all
            the way through to successful delivery, while keeping a close eye on
            user experience and business objectives.*/}
          </h4>
        </div>
        <div className="container">
          {activityData.map((activity, index) => (
            <div className="grid-sm-12 grid-sm-6" key={index}>
              <div className="activity-section-item">
                <SquareIcon icon={activity.icon} color={activity.color} />
                <div className="activity-section-item-title-container">
                  <h4 className="activity-section-item-title">
                    {activity.title}
                  </h4>
                </div>
                <Tag color="green" title={activity.tag} />
                <p>{activity.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ActivitySection;
