import dayjs from "dayjs";
import About from "../../models/About";

const aboutData: About[] = [
  { title: "Enghien-Les-Bains, FR", subtitle: "Born In" },
  { title: "Jul 3rd 1996", subtitle: "Birth date" },
  { title: "2019", subtitle: "Graduation" },
  { title: "Santa Monica, CA", subtitle: "Current location" },
  { title: dayjs().diff("1996-07-03", "year").toString(), subtitle: "Age" },
  {
    title: dayjs().diff("1996-07-03", "days").toString(),
    subtitle: "Days alive",
  },
  // { title: "Mar 30th 2019", subtitle: "Employed since" },
  // {
  //   title: dayjs().diff("2019-03-30", "days").toString(),
  //   subtitle: "Days employed",
  // },
];

export default aboutData;
