import React from "react";
import "./styles.scss";
import Button from "../../../components/Button";
import CircleText from "../../../components/CircleText";
import * as Scroll from "react-scroll";

const HomeSection: React.FC = () => {
  return (
    <section className="main-section">
      <div className="main-section-container">
        <h5>Senior Software Engineer @ Disney Streaming</h5>
        <h1>
          Alexandre <span>Toubiana</span>
        </h1>
        <p>I'm a French software engineer living in Santa Monica, CA</p>
        <div className="main-section-buttons">
          <Button
            onClick={() => {
              window.open("mailto:alexandre@toubiana.dev");
            }}
          >
            Contact
          </Button>
          <Button
            onClick={() => {
              window.open(require("../../../assets/data/resume.pdf"), "_blank");
            }}
            color="yellow"
          >
            Download Resume
          </Button>
        </div>
        <CircleText
          text="Learn More • Learn More • Learn More •"
          onClick={() =>
            Scroll.scroller.scrollTo("about", { offset: -110, smooth: true })
          }
        />
      </div>
    </section>
  );
};

export default HomeSection;
